import React from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap'

export const Notice = () => {
  return (
    <div>
        <Container className='mt-3'>
            <Row>
                <Col md={12}>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th>Notice Title</th>
                                <th>Published Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                                <td>---</td>
                            </tr>
                            
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>

    </div>
  )
}
