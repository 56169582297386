import React from 'react'
import './SubPrograms.css';
import { Col, Container, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons"


const SubPrograms = () => {
  return (
    <div>
           
        <Row className='programs'>
            <Col md={3} className='d-flex-center text-white' style={{fontSize: '25px'}}>
                আমাদের বিশেষ কর্মসূচী
            </Col>


            <Col md={3} className='d-flex-center py-4'>
                <div className='icon my-3'>
                    <img src="./com-img/cc.png" className='img-fluid' alt='CC Camera'/>
                </div>
                <div className='icon-text text-white'>
                    অত্র প্রতিষ্ঠানটি সিসি ক্যামেরা দ্বারা সার্বক্ষনিক মনিটরিং করা হয়। 
                </div>
            </Col>

            
            

            <Col md={3} className='d-flex-center py-4'>
                <div className='icon my-3'>
                    <img src="./com-img/fingureM.png" className='img-fluid' alt='Fingure Mechine' style={{padding:"10px"}}/>
                </div>
                <div className='icon-text text-white'>
                    বয়োমেট্রিক্স মেশিন ব্যবহার করে অটো হাজিরা নিশ্চিত করা হয়।
                </div>
            </Col>


            

            <Col md={3} className='d-flex-center py-4'>
                <div className='icon my-3'>
                <img src="./com-img/das.jpg" className='img-fluid' alt='Fingure Mechine' style={{padding:"20px"}}/>
                </div>
                <div className='icon-text text-white'>
                   সফটওয়্যার এর মাধ্যমে সকলপ্রকার লেনদেন পরিশোধ করার সুযোগ।
                </div>
            </Col>


            
        </Row>

    </div>
  )
}

export default SubPrograms
