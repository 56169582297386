import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Location from '../../components/Location/Location'


export const ContactUs = () => {
  return (
    <div>
        <Container className='my-3'>
            <Row>
                <Col md={12}>
                        <Location />
                    <div className='py-3'>
                    খলিলগঞ্জ বাজার রোড, পানি উন্নয়ন বোর্ড, কুড়িগ্রাম সদর, কুড়িগ্রাম, ইমেইল:vpikurigram@gmail.com, মোবাইল: + 88 01764-175232
                    </div>
                </Col>

            </Row>

        </Container>
    </div>
  )
}
