import React from 'react';
import ReactDOM from 'react-dom/client';



import './index.css';

import Header from './components/Header/Header';

import reportWebVitals from './reportWebVitals';
import Motive from './components/Motive/Motive';
import CountUpp from './components/CountUp/CountUpp';
import Location from './components/Location/Location';
import Footer from './components/Footer/Footer';
import Admission from './components/Admission/Admission';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import LogIn from './Elements/Login/LogIn';
import Dashboard from './Dashboard/Dashboard';
import ShortCourse from './ShortCourse/ShortCourse';
import { Notice } from './Elements/Notice/Notice';
import { Gallery } from './Elements/Gallery/Gallery';
import { ContactUs } from './Elements/ContactUs/ContactUs';


const route = createBrowserRouter([
  {
    path: "/",
    element: <>
    <Header />

    <Motive />
    <CountUpp />
    <Admission />
    <ShortCourse />
    
    <Location />
    <Footer />
    </>
  }, 
  {
    path: "/notice",
    element: <>
      <Header />
      <Notice />
      <Footer />
    </>
  },
  {
    path: "/admission",
    element: <>
      <Header />
      <Admission />
      <Footer />
    </>
  },
  {
    path: "/gallery",
    element: <>
      <Header />
      <Gallery />
      <Footer />
    </>
  },
  {
    path: "/contact",
    element: <>
      <Header />
      <ContactUs />
      <Footer />
    </>
  },
  {
    path: "/log",
    element : <LogIn />
  },
  {
    path: "/dashboard",
    element : <Dashboard />
  }

])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
    <RouterProvider router={route} />
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
