import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.css";
const Footer = () => {
  return (
    <footer className="footer pt-5">
      <Container>
        <Row>
          <Col md={3} sm={12} className="footerCol">
            <h6 className="text-uppercase font-weight-bold">
              <strong>Vision Polytechnic Institute</strong>
            </h6>
            <hr
              className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
              style={{ width: "60px" }}
            />
            <p>
              <div>
                <a href="#">
                  <img src="./vpilogo.png" alt="vpilogo" className="vpilogo" />
                </a>
              </div>
            </p>
          </Col>

          <Col md={3} sm={12} className="footerCol">
            <h6 className="text-uppercase font-weight-bold">
              <strong>Quick Support </strong>
            </h6>
            <hr
              className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
              style={{ width: "60px" }}
            />

            <p>
              <a href="#"> All Notice </a>
            </p>
            <p>
              <a href="#"> About Admission</a>
            </p>

            <p>
              <a href="#"> Admission Form </a>
            </p>
            <p>
              <a href="#"> Class Routine </a>
            </p>
            <p>
              <a href="#"> Exam Result </a>
            </p>
            <p>
              <a href="#">BTEB Exam Result</a>
            </p>
          </Col>

          <Col md={3} sm={12} className="footerCol">
            <h6 className="text-uppercase font-weight-bold">
              <strong>Useful links</strong>
            </h6>
            <hr
              className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
              style={{ width: "60px" }}
            />
            <p>
              <a href="http://www.moedu.gov.bd/">Ministry Of Education</a>
            </p>

            <p>
              <a href="http://www.techedu.gov.bd/">
                Directorate Of Technichal Education
              </a>
            </p>

            <p>
              <a href="http://www.bteb.gov.bd/">
                Bangladesh Technical Education Board (BTEB)
              </a>
            </p>

            <p>
              <a href="http://www.step-dte.gov.bd/">
                Skills And Training Enhancement Project
              </a>
            </p>

            <p>
              <a href="http://www.bpsc.gov.bd/">
                Bangladesh Public Service Commission (BPSC)
              </a>
            </p>

            <p>
              <a href="http://www.bcc.gov.bd/">
                Bangladesh Computer Council (BCC)
              </a>
            </p>
          </Col>

          <Col md={3} sm={12} className="footerCol">
            <h6 className="text-uppercase font-weight-bold">
              <strong>Contact</strong>
            </h6>
            <hr
              className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
              style={{ width: "60px" }}
            />
            <p>Khalilgonj Bazar Road,<br/>
            Water Development Board, <br/> Kurigram Sadar, Kurigram</p>
            <p>
               vpikurigram@gmail.com
            </p>
            <p>
             + 88 01764-175232
            </p>
          </Col>
        </Row>
      </Container>
      
    </footer>
  );
};

export default Footer;
