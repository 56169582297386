import React, { useEffect } from 'react'
import './Admission.css'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons"
import Aos from 'aos'
const Admission = () => {

    useEffect(()=>{
        Aos.init();
    });
    
  return (
    <div className="admission_div text-center py-5">
        <h4 className='pb-5'>ভর্তি সংক্রান্ত প্রয়োজনীয় তথ্যঃ</h4>
        <Container>
            <Row>
                <Col md={4} sm={12}>
                
                    <Card className='card' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" data-aos-delay="100">
                        <Card.Body>
                            <Card.Title>ভর্তির যোগ্যতাঃ</Card.Title>
                            <Card.Text className='add_list'>
                                <ul>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এস.এস.সি/সমমান পরীক্ষায় পাশ। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> নূন্যতম জিপিএ-2.00। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> যে কোন বিভাগ যে কোন গ্রুপ। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> বয়স সিথিল যোগ্য। </li>
                                </ul>
                            </Card.Text>
                        </Card.Body>

                    </Card>
                </Col>
                <Col md={4} sm={12} data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" data-aos-delay="400" >
                    <Card className='card'>
                        <Card.Body>
                            <Card.Title>ভর্তির প্রয়োজনীয় কাগজ পত্রাদিঃ</Card.Title>
                            <Card.Text className='add_list'>
                                <ul>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এস.এস.সি/সমমান পরীক্ষার মূল নম্বরপত্র (মার্কসীট)। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> মূল নম্বরপত্রের (মার্কসীট) ফটোকপি ০৩টি।</li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> প্রশংসাপত্রের ফটোকপি ০১টি। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> ছবি ০৪ কপি (পাসপোর্ট ০২, স্ট্যাম্প সাইজ ০১ কপি)। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> অভিভাবকের ছবি ০১ কপি। </li>
                                </ul>
                            </Card.Text>

                        </Card.Body>

                    </Card>
                </Col>
                <Col md={4} sm={12} data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" data-aos-delay="800" >
                    <Card className='card'>
                        <Card.Body>
                            <Card.Title>ভর্তির বিশেষ অফারঃ</Card.Title>
                            <Card.Text className='add_list'>
                                <ul>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> সকল শিক্ষার্থীদের জন্য সেমিষ্টার প্রতি ৪০০০/- টাকা বৃত্তির ব্যবস্থা। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এস.এস.সি/সমমান পরীক্ষায় উত্তীর্ণ জিপিএ ৫.00 প্রাপ্ত শিক্ষার্থীদের ভর্তি ফি সম্পূর্ণ ফ্রি।</li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> সকল শিক্ষার্থীদের জন্য মাসিক ৫০০/- টাকা পরিশোধে পড়াশুনা করার সুযোগ। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এককালিন কোর্স ফি পরিশোধে ১০% ছাড়!!! </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এইচ.এস.সি/সমমান পরীক্ষায় উত্তীর্ণ শিক্ষার্থীদের সরাসরি ৩য় পর্বে ভর্তির সুযোগ। </li>
                                    <li> <FontAwesomeIcon icon={faHandPointRight} /> এইচ.এস.সি(ভোকেশনাল)পরীক্ষায় উত্তীর্ণ শিক্ষার্থীদের সরাসরি ৪র্থ পর্বে ভর্তির সুযোগ। </li>
                                </ul>
                            </Card.Text>

                        </Card.Body>

                    </Card>
                </Col>
            </Row>

        </Container>

    </div>
  )
}

export default Admission
