import React, { useEffect } from "react";

import { Container, Row, Col } from "react-bootstrap";
import "./ShortCourse.css";
import Aos from 'aos'
const ShortCourse = () => {
  useEffect(()=>{
    Aos.init();
});
  return (
    <div>
      <Container className="shortCourseContainer py-3">
        <h3>আমাদের সর্টকোর্স সমূহ.....</h3>
        <Row className="pt-3">
          <Col md={4} data-aos="fade-right" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
            <div className="shortCourseCol">
              <div className="courseImg">
                <img src="./short-course/ma-1.jpg" alt="" className="img-fluid" />
              </div>
              <div className="courseText">Office Application
              <br/>
                <span style={{fontSize:"12px"}}> 
                    (Ms Word, Ms Excel, Ms Powerpoint, Ms Access)
                </span>
              </div>
            </div>
          </Col>

          <Col md={4} data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
            <div className="shortCourseCol">
              <div className="courseImg">
                <img src="./short-course/graphic.jpg" alt="" className="img-fluid" />
              </div>
              <div className="courseText">Graphics Design
              <br/>
                <span style={{fontSize:"12px"}}> 
                    (Adobe Photoshop, Adobe Illustrator)
                </span>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="shortCourseCol">
              <div className="courseImg">
              <img src="./short-course/web.jpg" alt="" className="img-fluid" />
              </div>
              <div className="courseText">Web Developing
              <br/>
                <span style={{fontSize:"11px"}}> 
                    (HTML, CSS, Javascript, Laravel / Wordpress / Marn Stack)
                </span>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="pt-4">
          <Col md={4} data-aos="fade-right" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
            <div className="shortCourseCol">
              <div className="courseImg">
                <img src="./short-course/programing.jpg" alt="" className="img-fluid" />
              </div>
              <div className="courseText">Computer Programing
              <br/>
                <span style={{fontSize:"12px"}}> 
                    (Java Programing, Python Programing)
                </span>
              </div>
            </div>
          </Col>

          <Col md={4} data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
            <div className="shortCourseCol">
              <div className="courseImg">
                <img src="./short-course/soft.jpg" alt="" className="img-fluid" />
              </div>
              <div className="courseText">Software Developing
              <br/>
                <span style={{fontSize:"12px"}}> 
                    (.Net Fremwork)
                </span>
              </div>
            </div>
          </Col>

          <Col md={4} >
            <div className="shortCourseCol">
              <div className="courseImg">
                <img src="./short-course/Android.jpg" alt="" className="img-fluid" />
              </div>
              <div className="courseText">Android App Developing
              <br/>
                <span style={{fontSize:"12px"}}> 
                    (Android Studio)
                </span>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="pt-4">
          <Col md={4} data-aos="fade-right" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
            <div className="shortCourseCol">
              <div className="courseImg">
                <img src="./short-course/Networking.jpg" alt="" className="img-fluid" />
              </div>
              <div className="courseText">Computer Networking
              <br/>
                <span style={{fontSize:"12px"}}> 
                    (CCNA)
                </span>
              </div>
            </div>
          </Col>

          <Col md={4} data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="1000">
            <div className="shortCourseCol">
              <div className="courseImg">
              <img src="./short-course/Land.jpg" alt="" className="img-fluid" />
              </div>
              <div className="courseText">Land survey
              <br/>
                <span style={{fontSize:"12px"}}> 
                    (.........)
                </span>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="shortCourseCol">
              <div className="courseImg">
                <img src="./com-img/class.png" alt="" className="img-fluid" />
              </div>
              <div className="courseText">Spoken English
              <br/>
                <span style={{fontSize:"12px"}}> 
                    (..........)
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ShortCourse;
