import React, { useEffect, useState } from "react";
import "./CountUpp.css";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import ScrollTrigger from "react-scroll-trigger";
import Aos from 'aos'
const CountUpp = () => {
    const[counterOn, setCounterOn] = useState(false);
    useEffect(()=>{
      Aos.init();
    });
  return (
    <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=>setCounterOn(false)}>
      <div className="count_up" data-aos="fade-up" data-aos-duration="1500">
        <Container>
          <Row>
            <Col md={3} sm={12}>
              <div className="countDiv" style={{ background: "#475C6C" }}>
                <div className="countIcon">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <div className="countNumber">
                    {counterOn && <CountUp start={500} end={1150} duration={6}></CountUp>}+
                </div>
                <div className="countText">Total Student's</div>
              </div>
            </Col>

            <Col md={3} sm={12}>
              <div className="countDiv" style={{ background: "#47403E" }}>
                <div className="countIcon">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <div className="countNumber">
                    {counterOn && <CountUp start={100} end={720} duration={5}></CountUp>}+ 
                  
                </div>
                <div className="countText">Passing Student's</div>
              </div>
            </Col>

            <Col md={3} sm={12}>
              <div className="countDiv" style={{ background: "#707070" }}>
                <div className="countIcon">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <div className="countNumber">
                    {counterOn && <CountUp start={156} end={500} duration={7}></CountUp>}+ 
                  
                </div>
                <div className="countText">Etablished Student's</div>
              </div>
            </Col>

            <Col md={3} sm={12}>
              <div className="countDiv" style={{ background: "#B36464" }}>
                <div className="countIcon">
                  <FontAwesomeIcon icon={faUsers} />
                </div>
                <div className="countNumber">
                    {counterOn && <CountUp start={0} end={22} duration={5}></CountUp>}+ 
                  
                </div>
                <div className="countText">Total Teacher's</div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </ScrollTrigger>
  );
};

export default CountUpp;
