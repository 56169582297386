import React, { useState } from "react";
import "./css/main.css";
import "./css/util.css";
import "./LogIn.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";



const LogIn = () => {
  const [uName, setUname] = useState();
  const [uPass, setPass] = useState();
  const navigate = useNavigate();


  const logSubmit = ()=>{
    axios.post('http://localhost:3030/checkLogDat', {uName, uPass}).then((res)=>{
      
      if(res.data.status == 200){
        navigate('/dashboard')
      }
    }).catch((e)=>{
      
    });
  }
  return (
    <div>
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login100-pic js-tilt lock" data-tilt>
              <img src="./lock3.png" />
				
            </div>

           
            <div className="right_div ">
            <span className="login100-form-title">Member Login
              </span>
              
              <div className="wrap-input100 validate-input">
                 
                <input
                  className="input100"
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={(e)=>setUname(e.target.value)}
                 
                />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </div>

              <div
                className="wrap-input100 validate-input"
              >
                <input
                  className="input100"
                  type="password"
                  name="pass"
                  placeholder="Password"
                  onChange={(e)=>{setPass(e.target.value)}}
                  value={uPass}
                />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <FontAwesomeIcon icon={faLock} />
                </span>
              </div>

              <div className="container-login100-form-btn">
                <button className="login100-form-btn" onClick={logSubmit}>Login</button>
              </div>

              <div className="text-center p-t-12">
                <span className="txt1 mx-2">Forgot</span>
                <a className="txt2" href="#">
                  Username / Password?
                </a>
              </div>

              <div className="text-center p-t-136" style={{color: "#ddc9c9"}}>

                Create New Account
              </div>
            </div> 
              

             
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
