import React from 'react'

const Location = () => {
  return (
    <div>
        <h4 className='text-center m-5'>আমাদের ঠিকানা গুগল ম্যাপে দেখুন</h4>
        <div id="map-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.147341848833!2d89.6387440150375!3d25.798712713364008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e2c92163048b8b%3A0x907ab0c5a82eeb23!2sVision+Polytechnic+Institute!5e0!3m2!1sru!2sbd!4v1503155386349" width="100%" height="450" frameborder="0" style={{border:'0'}} allowfullscreen id="ifrem_id"></iframe>
        </div>
    </div>
  )
}

export default Location
