import React, { useEffect } from "react";
import "./Motive.css";
import { Col, Container, Row } from "react-bootstrap";
import Aos from "aos";
import SubPrograms from '../SubPrograms/SubPrograms'
const Motive = () => {
  useEffect(() => {
    Aos.init();
  });
  return (
    <div>
      <Container>
        <Row>
          <Col className="col-md-12 text-center pt-2 slowtext">
            আপনি কি একজন দক্ষ ইঞ্জিনিয়ার হিসেবে নিজেকে গড়ে তুলতে চান? <br />
            তাহলে চলে আসুন আমাদের প্রতিষ্ঠানে.......
          </Col>
        </Row>

        <Row className="motiveRow">
          <Col className="graduationImg" sm={12} md={4}>
            <img src="./gra.png" alt="graduation" className="img-fluid" />
          </Col>

          <Col className="col-md-8 justify-center">
            <span className="ourTech">আমাদের টেকনোলজি সমুহঃ</span>
            <Row>
              <Col className="col-md-4">
                <ul className="ch-grid">
                  <li>
                    <div className="ch-item">
                      <div className="ch-info">
                        <div className="ch-info-front civil-img">
                          <div className="img-cap">
                            <a href="" className="text-white">
                              সিভিল
                            </a>
                          </div>
                        </div>
                        <div className="ch-info-back">
                          <h3>Civil</h3>
                          <p>
                            If You Interested
                            <a href="" className="btn btn-success">
                              Read More
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>

              <Col className="col-md-4">
                <ul className="ch-grid">
                  <li>
                    <div className="ch-item">
                      <div className="ch-info">
                        <div className="ch-info-front et-img">
                          <div className="img-cap">
                            <a href="" className="text-white">
                              {" "}
                              ইলেকট্রিক্যাল{" "}
                            </a>
                          </div>
                        </div>
                        <div className="ch-info-back">
                          <h3>Electrical</h3>
                          <p>
                            If You Interested
                            <a href="" className="btn btn-success">
                              Read More
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col className="col-md-4">
                <ul className="ch-grid">
                  <li>
                    <div className="ch-item">
                      <div className="ch-info">
                        <div className="ch-info-front cmt-img">
                          <div className="img-cap">
                            <a href="" className="text-white">
                              কম্পিউটার
                            </a>
                          </div>
                        </div>
                        <div className="ch-info-back">
                          <h3>Computer</h3>
                          <p>
                            If You Interested
                            <a href="" className="btn btn-success">
                              Read More
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>

        <SubPrograms />

        <section className="text-center mt-5">
          <h3>যে কারণে আমাদের প্রতিষ্ঠান সেরা....</h3>
        </section>

        <Row>
          <Col md={3}>
            <div
              className="hvr-bob"
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <div className="b-ins my-4 my-box">
                <div className="a-icon">
                  <i className="fas fa-map-marker-alt"></i>
                </div>
                <div className="w-title mb-2">রংপুর বিভাগে ১ম স্থান</div>
                <div className="block-with-text first-plase">
                  পর্ব সমাপনী পরীক্ষায় ১০০% পাশের হার থাকায় রংপুর বিভাগে ১ম
                  স্থান অধিকারী প্রতিষ্ঠান হিসেবে ভিশন পলিটেকনিক ইনস্টিটিউট,
                  কুড়িগ্রাম পরিচিতি লাভ করে। যা অত্র প্রতিষ্ঠানের জন্য অনেক বড়
                  সাফল্য।
                </div>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <div
              className="hvr-bob"
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <div className="b-ins my-4  my-box">
                <div className="a-icon">
                  <i className="fas fa-chalkboard-teacher"></i>
                </div>
                <div className="w-title mb-2">গাইড টিচার্স পদ্ধতি</div>
                <div className="block-with-text guide-teacher">
                  এই পদ্ধতিতে আমরা প্রতিটি শিক্ষার্থীকে একজন গাইড টিচার্স
                  নিযুক্তের মাধ্যমে সার্বক্ষনিক মনিটরিং করার হয়। শিক্ষার্থীর
                  পড়াশুনা সংক্রান্ত সকল সমস্যা সমাধান করা হয় ।
                </div>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <div
              className="hvr-bob"
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <div className="b-ins my-4  my-box">
                <div className="a-icon">
                  <i className="fas fa-address-book"></i>
                </div>
                <div className="w-title mb-2">সর্বাধিক ক্লাস ব্যবস্থা</div>
                <div className="block-with-text class">
                  একজন শিক্ষার্থীর জন্য আমরা সর্বাধিক ব্যবহারিক ও তাত্নিক ক্লাস
                  নিশ্চিতের মাধ্যমে শিক্ষার্থীকে যুগোপযুগি করে গড়ে তুলতে আমরা
                  সর্বদাই প্রস্তুত থাকি। যা শিক্ষার্থীর উন্নত ক্যারিয়ার গড়তে
                  গুরুত্বপূর্ণ অবদান রাখে
                </div>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <div
              className="hvr-bob"
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-delay="700"
            >
              <div className="b-ins my-4  my-box">
                <div className="a-icon">
                  <i className="fas fa-home"></i>
                </div>
                <div className="w-title mb-2">আবাসিক সু-ব্যবস্থা</div>
                <div className="block-with-text res">
                  প্রতিষ্ঠানের নিজেস্ব হোস্টেল ব্যবস্থা থাকায় দুর দুরান্ত থেকে
                  আগত শিক্ষার্থীরা এই সুবিধার আওতাভুক্ত থাকে।
                </div>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <div
              className="hvr-bob"
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <div className="b-ins my-4  my-box">
                <div className="a-icon">
                  <i className="fas fa-wifi"></i>
                </div>
                <div className="w-title mb-2">ওয়াই-ফাই জোন</div>
                <div className="block-with-text wifi">
                  প্রতিষ্ঠানে সম্পূর্ণ ওয়াই-ফাই জোন থাকায় শিক্ষার্থীরা
                  প্রতিষ্ঠানে অবস্থান করে এই সুবিধার আওতায় থাকতে পারে।
                </div>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <div
              className="hvr-bob"
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <div className="b-ins my-4  my-box">
                <div className="a-icon">
                  <i className="fas fa-futbol"></i>
                </div>
                <div className="w-title mb-2">কো কারিকুলাম এক্টিভিটিস</div>
                <div className="block-with-text co-cur">
                  পড়াশুনার পাশাপাশি কো কারিকুলাম এক্টিভিটিস একজন শিক্ষার্থীর
                  জন্য খুবই গুরুত্বপূণ। এই এক্টিভিটিস গুলি শিক্ষার্থীদের
                  সমস্যা-সমাধান, যুক্তি, সমালোচনা চিন্তা, সৃজনশীল চিন্তাভাবনা,
                  যোগাযোগ এবং সহযোগী দক্ষতা বিকাশে সহায়তা করে।
                </div>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <div
              className="hvr-bob"
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <div className="b-ins my-4  my-box">
                <div className="a-icon">
                  <i className="fas fa-tree"></i>
                </div>
                <div className="w-title mb-2" style={{ fontSize: "14px" }}>
                  ধুমপান ও রাজনীতি মুক্ত পারিবেশ
                </div>
                <div className="block-with-text no-smoke">
                  আমাদের প্রতিষ্ঠানটি সম্পূর্ণ ধুমপান ও রাজনীতি মুক্ত পরিবেশ
                  থাকায় শিক্ষার্থীরা ভালোভাবে তাদের পড়াশুনা করার সুযোগ পায়। যা
                  শিক্ষার্থীদের উন্নত ভবিষ্যৎ গঠনে গুরুত্বপূর্ণ ভূমিকা রাখে।
                </div>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <div
              className="hvr-bob"
              data-aos="flip-up"
              data-aos-duration="1000"
              data-aos-delay="700"
            >
              <div className="b-ins my-4  my-box">
                <div className="a-icon">
                  <i className="fas fa-user-alt"></i>
                </div>
                <div className="w-title mb-2">সুদক্ষ শিক্ষক মন্ডলী</div>
                <div className="block-with-text teacher">
                  এম.এস.সি, বি.এস.সি ও মাস্টার্স ডিগ্রি সহ অবসর প্রাপ্ত মানসম্মত
                  শিক্ষক মন্ডলী দ্বারা যত্ন সহকারে শিক্ষার্থীদের পাঠদান করা হয়।
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Motive;
