import React, { useEffect, useState } from 'react'
import './Header.css';
import AOS from 'aos';
import Wave from 'react-wavify'
import { Outlet, Link } from 'react-router-dom';


const Header = () => {
    const [headerClassName, setHeaderClassName] = useState(false);
    const handlerScroll = ()=> {
      const navId = document.getElementById('navId');
        //document.getElementById("navId").classList.add("show-example");
        if(window.pageYOffset >= 100){
            document.getElementById("navId").classList.add("scroll-active");
        }else{
            document.getElementById("navId").classList.remove("scroll-active");
        }
    }

    useEffect(()=>{
        window.onscroll = () => handlerScroll();
        AOS.init();
    }, [])

  return (
    <div>
      

    <header>
        <div className="overlay"></div>

        <nav className='nav fixed-top'>

            <div className="container-fluid" id='navId'>
                <input type="checkbox" className='checkbox' id="checkbox"/>

                <div className="logo">
                    <img src="./vpilogo.png" alt="" />
                </div>

                <div className="navbars" id="">
                    <div className="res-logo">
                        <img src="./vpilogo.png" alt="" />
                        <p>vision polytechnic institute
                            <br/>
                            Mobile: 01764-175232
                        </p>
                    </div>
                    <nav>
                    <ul className="navbar">
                        <li className="navitem ">
                            <Link className="navlink" to="/">HOME</Link>
                        </li>
                       
                        <li className="navitem ">
                            <Link className="navlink" to="/admission">ADMISSION</Link>
                        </li>
						
						 <li className="navitem ">
                            <Link className="navlink" to="/notice">NOTICE</Link>
                        </li>
						
						 <li className="navitem ">
                            <Link className="navlink" to="/gallery">GALLERY</Link>
                        </li>
					
						
						 <li className="navitem ">
                            <Link className="navlink" to="/contact">CONTUCT US</Link>
                        </li>
						
						<div className="  d-none d-lg-block ">
						</div>
					
					
                    </ul>  
                    </nav>
                                    
                </div>

                <div className="bar">
                    <div className="bar-item"></div>
                    
                </div>
                
            </div>

        </nav>
  
        <div id="intro" className="view">

            <div className="mask rgba-black-strong">

                <div className="container-fluid d-flex align-items-center justify-content-center h-100">

                    <div className="row d-flex justify-content-center text-center">

                        <div className="col-md-12">

                          
                            <h2 className="display-4 font-weight-bold pt-5 mb-2" data-aos="fade-left" data-aos-duration="1000">Vision Polytechnic Institute</h2>

                           
                          
							<div data-aos="fade-right" data-aos-duration="1000">
                            <h4 className="  my-4">
								R.K Road, Khalilgonj, Kurigram.
							</h4>
							<a className="about_us_btn">About Us</a>
                                <br/>
                                <br/>
							<span className="h2 add" >Admission Going On</span>
						
							</div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div className="wav path-0">
       
        <Wave fill='#ffffff'
            paused={false}
            style={{ display: 'flex' }}
            options={{
            height: 90,
            amplitude: 50,
            speed: 0.15,
            points: 3
            }}
        />
       
        </div>
        
    </header>
   
    </div>
  )
}

export default Header
