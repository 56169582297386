import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './Gallery.css'
export const Gallery = () => {
  return (
    <Container className='mt-5'>
        <Row>
            <Col md={3} className='photo'>
                <img src="./vpilogo.png" alt="" className='img-fluid' />
            </Col>
            
        </Row>
    </Container>
  )
}
