import React from 'react'

const Dashboard = () => {
  return (
    <div>
      hello dashboard
    </div>
  )
}

export default Dashboard
